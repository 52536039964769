<!-- 资产中心-收支明细 -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select
              v-model="searchData.searchType"
              placeholder="请选择关键字类型"
              style="width: 120px"
            >
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input
              class=""
              placeholder="搜索关键字"
              prefix-icon="el-icon-search"
              v-model="searchData.searchValue"
              @keyup.enter.native="search"
              style="width: 260px"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="状态 ：" style="margin-left: 2%">
            <el-select
              v-model="searchData.status"
              placeholder="状态"
              style="width: 120px"
            >
              <el-option
                v-for="item in statusMap2Array"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="margin-left: 30px">
            <el-select
              v-model="searchData.timeType"
              placeholder="分账时间"
              style="width: 120px;margin-right:10px"
            >
              <el-option :key="2" label="支付时间" :value="1"></el-option>
              <el-option :key="1" label="分账时间" :value="2"></el-option>
            </el-select>
            <el-date-picker
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="searchData.selectTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button
            >
          </el-form-item>
        </el-form>
        <div style="text-align: center; color: red">
          <span style="padding-right: 35px" v-if="showStartEndTime">
            {{ showStartEndTime[0] }}至{{ showStartEndTime[1] }}
          </span>
          <span style="padding-right: 35px">待分账：{{ sumData.waitTotalAmout }}元</span>
          <span style="padding-right: 35px">已分账：{{ sumData.splittingTotalAmout }}元</span>
          <span>分账总金额：{{ sumData.totalAmout }}元</span>
        </div>
      </template>

      <template v-slot:table="row">
        <el-table :data="tableData" :height="row.heightText" v-if="row.height" @sort-change="openTimeSort">
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
        </el-table-column>
          <el-table-column prop="id" label="分账流水号"></el-table-column>
          <el-table-column label="分账金额" prop="amout"> </el-table-column>
          <el-table-column label="分账状态">
            <template slot-scope="scope">
              <span>{{statusMap[scope.row.status]}}</span>
            </template>
          </el-table-column>
          <el-table-column label="分账明细" width="700">
            <template slot-scope="scope">
              <div :style="{'background':scope.row.acctSplitInfo.length==index+1?'#ccc!important':''}" class="acct-split" v-for="(item,index) in scope.row.acctSplitInfo" :key="index"  >
                <span>汇付ID：{{item.huifu_id}}</span>
                <span>分账方名称：{{item.name}}</span>
                <span>分账金额：{{item.div_amt}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="orderId" label="订单编号"></el-table-column>
          <el-table-column prop="orderAmount" label="订单金额"></el-table-column>
          <el-table-column prop="payTime" label="支付时间"></el-table-column>
          <el-table-column prop="splittingTime" label="分账时间"></el-table-column>
        </el-table>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum"
          >
          </el-pagination>
        </div>
      </template>
    </table-height-auto>
  </div>
</template>

<script>
  import {URL} from "../../../config";
  import {disposeSearchData, getNextDayTimestamp, isEmpty, parseTime, parseTimeArray,} from "../../../assets/js/utils";
  // import {townShipType, townShipTypeArray, typeType, typeTypeArray} from "../../../assets/js/status.js";
  import {plusDownloadExcelTask} from "../../../store/excel";

  export default {
  name: "storeMoneyFlowingWater",
  components: {},
  data() {
    return {
      showStartEndTime: null,
      loading: false,
      // typeType,
      // typeTypeArray,
      // townShipType,
      // townShipTypeArray,
      tableData: [],
      searchData: {
        searchType: "orderGoodsId",
        orderGoodsId: "",        
        orderId: "",
        status: "",
        timeType: 1
      },
      statusMap: {
        0: "待分账",
        1: "已分账",
      },
      statusMap2Array: [
        { id: "", name: "全部" },
        { id: 1, name: "已分账" },
        { id: 0, name: "待分账" }
      ],
      searchKeyType: [
        { id: "orderGoodsId", name: "分账流水号" },
        { id: "orderId", name: "订单编号"}
      ],
      sumData: {
        "totalAmout": 0,
        "waitTotalAmout": 0,
        "splittingTotalAmout": 0
      },
      /* 分页参数 */
      pageSize: 10,
      totalListNum: 10,
      currentPage: 1,
    };
  },
  created() {
    let endText = new Date();
    let startText = new Date();
    startText.setTime(startText.getTime() - 3600 * 1000 * 24 * 30);
    this.searchData.selectTime = [parseTime(startText), parseTime(endText)];
    this.showStartEndTime = this.searchData.selectTime;

    this.getList();
  },

  methods: {
    openTimeSort(attr) {
      if (attr.order == 'ascending') {
        // 升序
        this.currentPage = 1;
        this.searchData.sortType = 1;
        this.getList();
      } else if (attr.order == 'descending') {
        this.currentPage = 1;
        this.searchData.sortType = 2;
        this.getList();
      }
    },
    handleClick(tab, event) {
      this.currentPage = 0;
      this.getList();
    },
    /**
     * 拼接搜索参数
     */
    jointSearchData() {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      data = Object.assign(data, this.searchData);
      disposeSearchData(data);
      let selectTime = data.selectTime;
      let startTime = 0;
      let endTime = 0;

      if (!isEmpty(selectTime)) {
        let times = selectTime.map((res) => new Date(res).getTime());
        startTime = times[0];
        endTime = getNextDayTimestamp(times[1]);
      }

      data.startTime = startTime;
      data.endTime = endTime;

      delete data.selectTime;
      return data;
    },
    /**
     * 搜索
     */
    search() {
      this.currentPage = 1;
      this.getList();
      this.showStartEndTime = this.searchData.selectTime;
    },
    getList() {
      return new Promise((resolve, reject) => {
        let data = this.jointSearchData();
        this.loading = true;
        this.axios
          .post(URL.suppWallet.getSupplierRevenueFlowList, this.qs.stringify(data))
          .then((res) => {
            this.sumData =  res.data.sumData || {}
            let list = res.data.page.records || [];
            parseTimeArray(list, ["createTime"]);
            this.tableData = list;
            this.totalListNum = parseInt(res.data.page.total);
            resolve(res);
          })
          .catch((res) => {
            this.tableData = [];
          })
          .finally((res) => {
            this.loading = false;
          });
      });
    },
    /* 分页 */
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    /**
     * 导出excel
     */
    downloadExcel() {
      let searchData = this.jointSearchData();
      let data = {
        name: "资产中心收入流水报表",
        params: {
          excelType: 1011,
          excelParameterJson: JSON.stringify(searchData),
        },
      };
      plusDownloadExcelTask.call(this, data);
    },
  },
};
</script>

<style lang="less" scoped>
.acct-split{
  padding: 5px;
  margin-bottom: 5px;
  background: #f5f5f5;
  >span{
    display: inline-block;
    line-height: 18px;
    font-size: 12px;
    margin-right: 20px;
    min-width: 200px;
  }
  &:last-child{
    margin-bottom: 0;
  }
}
</style>